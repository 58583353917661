<template>
  <div result-page>
    <div class="message-group">
      <FontIcon :class="{success : isSuccess }" :name="icon" />
      <p v-html="resultMessage" />
    </div>
    <div class="bottom">
      <GpButton v-if="showHistory" type="line-green" @click="goTransferHistory">{{ $t('myInfo.viewHistory') }}</GpButton>
      <GpButton type="submit-green" @click="move">{{ $t(buttonName) }}</GpButton>
    </div>
  </div>
</template>

<script>
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import { STATUS, STEP } from '@/constants/base/my-page';
import FontIcon from '@shared/components/common/FontIcon.vue';
import Specific from '@shared/types/Specific';

export default {
  name: 'ResultPage',
  components: { GpButton, FontIcon },
  props: {
    structure: Specific,
    rootRoute: String,
    type: { type: String, default: 'SUCCESS' },
    message: { type: String, default: 'Successfully message' },
    params: { type: Specific, default: null, }
  },
  computed: {
    isSuccess() {
      return this.type === STATUS.SUCCESS;
    },
    isError() {
      return this.type === STATUS.ERROR;
    },
    icon() {
      return this.isSuccess ? 'check-over-circle-regular' : 'exclamation-circle-regular';
    },
    showHistory() {
      return this.structure?.showHistory;
    },
    buttonName() {
      return this.structure?.buttonName;
    },
    resultMessage() {
      const contact = this.params?.contact;
      if(contact){
        if(contact === 'contact') return `${this.$t(this.message, this.params)}<br/>${this.$t('myInfo.contactFailedMessage')}`;
        if(contact === 'contact-error') return `${this.$t(this.message, this.params)}<br/>${this.$t('myInfo.contactErrorMessage')}`;
      }
      return this.$t(this.message, this.params);
    }
  },
  methods: {
    move() {
      if (this.structure?.redirectUri) {
        location.href = this.structure.redirectUri;
        return;
      }
      if (this.structure?.backRoute) {
        this.replaceRouteName(this.structure.backRoute);
        this.$emit('reset');
        return;
      }
      this.replaceRouteName(this.rootRoute);
      this.$emit('reset');


    },
    goTransferHistory() {
      this.replaceRouteName('NpTransferHistory');
      this.$emit('reset');
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[result-page] { flex: 1; .flex(); .flex-dc(); .ph(20); .abs();.wh(100%); z-index: 99;
  .message-group { .flex();.flex-dc();.justify-center(); .items-center();.gap(10); flex: 1;
    > p { .c(#1B1B1B); .fs(16); text-align: center;}
    > i { .fs(30); .c(@c-red);
      &.success {.c(@gp-green)}
    }
  }
  .bottom {.flex(); .gap(10);}
}
</style>
