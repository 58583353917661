<template>
  <div loading-page>
    <div class="dim"></div>
    <div class="fake-header"></div>
    <div class="fake-body">
      <div class="progress-group">
        <div class="progress-circle">
          <div class="inner-progress"></div>
        </div>
        <p>{{ message }}</p>
      </div>
    </div>

  </div>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
export default {
  name: 'LoadingPage',
  components: { FontIcon },
  props: {
    type: {
      type: String,
      default: 'SUCCESS'
    },
    message: {
      type: String,
      default: 'progress'
    }
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[loading-page] { .abs(); .t(0); .l(0); .w(100%); .h(100%); z-index: 999; .flex();.justify-center(); .items-center(); .flex-dc();
  .dim {.bgc(rgba(0, 0, 0, 0.30)); .wh(100%); .abs(); .l(0); .t(0);}
  .fake-header { .h(105px); .w(100%); }
  .fake-body { .w(100%); .h(calc(100% - 105px)); .flex(); .justify-center(); .items-center(); .pb(60); .bgc(#fff);
    .progress-circle {.wh(40); .br(100); .flex();.justify-center(); .items-center(); animation: rotate-circle 2s linear infinite;
      background-image: linear-gradient(to top, @gp-green 70%, transparent 70%),
      linear-gradient(to right, #D4D4D8 0%, #D4D4D8 30%);
      background-origin: border-box;
      background-clip: content-box, border-box;
      // background: linear-gradient(to top right, @gp-green 70%, #D4D4D8 30%); animation: rotate-circle 2s linear infinite;
      .inner-progress {.wh(30); .bgc(rgba(0, 0, 0, 0.3)); .br(100); }
    }
    .progress-group { .flex();.flex-dc();.justify-center(); .items-center();.gap(10);
      > img { animation: rotate-circle 2s linear infinite; transform-origin: center; }
      > [font-icon] {.fs(30); .c(@gp-green); animation: rotate-circle 2s linear infinite; transform-origin: center;}
      > p { .c(#1B1B1B); .fs(14) }
    }
  }


}
</style>